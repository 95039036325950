const Swal = require('../../node_modules/sweetalert2');
const party = require('../images/party-popper.png');

/* Envoi email Footer*/
function isEmailFooterValid() {
  const value = document.getElementById('newsletter2').value
  return /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/.test(value)
}

let errorlabelEmailFooter = document.getElementById('errorlabelEmail2')
document.getElementById('newsletter2').addEventListener('keyup', function () {
  if (!isEmailFooterValid()) {
    errorlabelEmailFooter.classList.remove('overflow-hidden')
  } else {
    errorlabelEmailFooter.classList.add('overflow-hidden')
  }
})

/*soumission de l'email du footer*/
function submitContact() {
  if (isEmailFooterValid()) {
    const adresses = {
      email: document.getElementById('newsletter2').value,
    }
    console.log(adresses)
    fetch('https://test.cookie.menu/api/contacts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(adresses),
    })
      .then((res) => {
        if (!res.ok) {
          throw res
        }
        return res.json()
      })
      .then((res) => {
        Swal.fire({
          text: 'Merci ! Vous serez parmi les premiers au courant des évolutions de Cookie Menu',
          imageUrl: party ,
          imageWidth: 35,
          imageHeight: 35,
          imageAlt: "confettis",
          confirmButtonText: 'Ok',
        })
        document.getElementById('newsletter2').value = ''
      })
      .catch(function (error) {
        const status = error.status
        switch (status) {
          case 400:
            Swal.fire({
              text: 'Veuillez renseigner votre email',
              confirmButtonText: 'ok',
            })
            break
          default:
            Swal.fire({
              text: "Une erreur est survenue, le compte n'a pas pu être créé",
              confirmButtonText: 'ok',
            })
        }
      })
  }
}

//let buttonFooter = document.getElementById('but-sub-footer');
let footerSubmit = document.getElementById('form-footer')
footerSubmit.addEventListener('submit', function (event) {
  event.preventDefault()
  // fonction de createAccount du formulaire
  grecaptcha.ready(function () {
    grecaptcha
      .execute('6Lc6ReEZAAAAABEG4FT8AdIgSM-3r-958r-xoUgU', {
        action: 'createAccount',
      })
      .then(function (token) {
        //console.log(token);
        const payload = {
          token: token,
        }
        fetch('https://test.cookie.menu/api/captchas/verify', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (!res.ok) {
              //console.log(res)
              throw res
            }
          })
          .then((res) => {
            //console.log(res)
            submitContact()
          })
          .catch(function (error) {
            console.log(error)
            const status = error.status
            //console.log(status)
          })
      })
      .catch(function (error) {
        console.log(error)
      })
  })
})

/*au load de la page celle ci se retrouve en haut*/
var target = window.location.hash,
  target = target.replace('#', '')

// delete hash so the page won't scroll to it
window.location.hash = ''
document.addEventListener('DOMContentLoaded', function () {
  //console.log('hello', target)
  goToSmooth(target)
})

/*fonction smooth pour les ancres*/
function goToSmooth(target) {
  if (target) {
    const fixedElementHeight = 80
    const ancre = document.querySelector('#' + target)
    const offsetTop = ancre.offsetTop - fixedElementHeight
    scroll({
      top: offsetTop,
      behavior: 'smooth',
    })
  }
}

/*import pour scroll beahvior smooth*/
import { smoothScrollAnchor } from 'smooth-scroll-anchor'
smoothScrollAnchor({
  behaviour: 'smooth',
  block: 'start',
  offset: 80,
})

/*import pour scroll beahvior smooth avec safari*/
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

